.nav-bar{
    border-bottom: 2px;
}


button{
    padding-top: 56px;
}

button:hover{
    scale: 1.1;
}


.icon-hover:hover{
    scale: 1.4;
}

a{
    font-weight: bold;
}
/* 
.t-head{
    background-color: #1B4965;
    color: white;
}
table{
    background-color:#62B6CB;
    color: white;
} */

.bg-patient{
   
    border-radius: 30px;
}

.bg-patient-header{
    /* background-color:#1B4965; */
    /* border-radius: 30px 30px 0 0 ; */
    /* color: white; */
}
.bg-patient-info{
    /* background-color:#ffffff; */
    color: black;
}

.primary-color{
    background-color: #1B4965;
    color: white;
}
.secondary-color{}

.border-end{
    /* border-radius: 0 0 30px 30px ; */
}

.first-consultation{
    /* border:#1B4965 solid 1px; */
}

paper{

}